/*-----------------------------------------------------------------------------------


    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Template Spacing CSS
    03. Plugin Custom CSS (Custom style to override plugin default style)
        03.01. AOS Animation
    04. Elements CSS
        04.01 Accordion CSS
        04.02 Brand CSS 
        04.03 Breadcrumb CSS
        04.04 Button CSS
        04.05 CTA CSS
        04.6 Form CSS
        04.7 Funfact CSS
        04.8 Icon Box CSS
        04.9 Newsletter CSS
        04.10 Pagination CSS
        04.11 Team CSS
    05. Header CSS
        05.01 Main Menu CSS
        05.02 Mobile Menu Toggle CSS
        05.03 Mobile Menu CSS
        05.04 Header Logo CSS
        05.05 Search Box CSS
    06. Intro CSS
        06.01. Home One Intro CSS
        06.02. Home Two Intro CSS
    07. Titles CSS
        07.01. Page Title CSS
        07.02. Section Title CSS
    08. Blog CSS
        08.01. Blog One CSS
        08.02. Blog Two CSS
        08.03. Blog Details CSS
    09. Pages CSS
        12.01 About CSS
        12.02 Portfolio CSS
        12.03 Success CSS
        12.04 Skill With Video CSS
        12.05 Contact CSS
        12.06 Work Details CSS
    10. Testimonial CSS
    11. Widget CSS
    12. Footer CSS




-----------------------------------------------------------------------------------*/
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800;900&display=swap");
@import url("../css/vendor/fontawesome.min.css");
@import url("../css/vendor/pe-icon-7-stroke.css");
@import url("../css/plugins/animate.css");

@import "root";
@import "~bootstrap/scss/bootstrap";
@import "common";
@import "spacing";
@import "elements";
@import "titles";
@import "plugins";
@import "header";
@import "intro";
@import "testimonial";
@import "pages";
@import "blog";
@import "widget";
@import "footer";
