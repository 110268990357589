html {
  scroll-behavior: smooth;
}

.myContainer {
  padding: 15px;
  /* max-width: 80%; */
  /* margin: auto; */
  display: flex;
  flex-wrap: wrap;
  width: 100%; /* Full width */
  box-sizing: border-box;
}

.myContainer .left {
  width: 100%; /* Full width on all devices */
  /* text-align: justify; */
}

.myContainer .left h1 {
  /* background-color: #3db5b1; */
  background-color: #0d2839;
  color: #fff;
  padding: 20px;
  text-align: center;
  margin: 0;
}

.myContainer .left h2,
.myContainer .left h3 {
  margin: 20px 0 10px;
  padding-left: 15px;
}

.myContainer .left p {
  margin: 10px 15px;
  padding: 0;
}

.myContainer .left ul {
  margin: 10px 30px;
  padding: 0;
}

.myContainer .left ul li {
  margin-bottom: 10px;
}

.myContainer .left strong {
  color: #0d2839;
}

.myContainer .left a {
  color: #0d2839;
  text-decoration: none;
}

.myContainer .left a:hover {
  text-decoration: underline;
}

.tableWrapper {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.tableWrapper .tableLeft {
  width: 50%;
}

.tableWrapper .tableRight {
  flex: 1;
  padding-bottom: 10px;
  padding-inline: 10px;
}

.tableWrapper .tableRow {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgray;
  padding-bottom: 3px;
  gap: 3px;
  align-items: center;
}

@media (min-width: 768px) {
  .myContainer {
    padding: 40px;
    max-width: 90%;
    margin: auto;
  }

  .myContainer .left {
    width: 100%; /* Full width for tablets */
  }

  .tableWrapper {
    flex-direction: row;
  }

  .tableWrapper .tableLeft {
    width: 50%;
  }

  .tableWrapper .tableRight {
    width: 50%;
    padding-inline: 20px;
  }
}

@media (min-width: 1024px) {
  .myContainer {
    padding: 80px;
  }

  .myContainer .left {
    width: 100%; /* Full width for larger screens */
  }
}

@media (max-width: 767px) {
  .myContainer {
    padding: 10px;
    max-width: 100%;
    margin: 0;
  }

  .tableWrapper {
    flex-direction: column;
    gap: 15px;
  }

  .tableWrapper .tableLeft,
  .tableWrapper .tableRight {
    width: 100%;
  }

  .tableWrapper .tableRow {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 3px;
    align-items: center;
  }
}
