/* 05.04 Header Logo CSS */

.header-logo {
  & a {
    position: relative;
    width: 100%;

    & img {
      width: 100%;
      padding: 15px 0;

      &.light-logo {
        position: absolute;
        top: 0;
        left: 0;

        opacity: 0;
      }

      &.dark-logo {
      }
    }
  }

  @media #{$extra-small-mobile} {
    & a {
      max-width: 120px;
    }
  }
}

.header-light {
  & .header-logo {
    & a {
      & img {
        &.light-logo {
          opacity: 1;
        }

        &.dark-logo {
          opacity: 0;
        }
      }
    }
  }
}
